<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    :formIsReadOnly="
      ['supercustomer', 'customer'].includes($store.state.AppActiveUser.role)
    "
    :showDeleteButton="true"
  />
</template>
<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase/firebaseConfig";

import FormPage from "@/components/penal-retail-helpers/FormPage";
let collectionName = "vehicles";
let myRelationAttributeId = "vehicleId";
let relatedEntities = [
  {
    collectionName: "bands",
    relationName: "vehiclesBands",
    attributeId: "bandId",
    casesRelationName: "casesBands",
  },
  {
    collectionName: "criminals",
    relationName: "criminalsVehicles",
    attributeId: "criminalId",
    casesRelationName: "casesCriminals",
  },
  {
    collectionName: "cases",
    relationName: "casesVehicles",
    attributeId: "caseId",
  },
];

import {
  recalculateAllCases,
  createRelatedCasesColumns,
} from "@/helpers/utils";
import bandsFormConfig from "@/helpers/bandsFormConfig";
import criminalsFormConfig from "@/helpers/criminalsFormConfig";
import vehiclesFormConfig from "@/helpers/vehiclesFormConfig";

let watch = {};
relatedEntities.forEach((r) => {
  watch[`${collectionName}${r.collectionName}`] = async function () {
    this.entityCases = await recalculateAllCases(
      collectionName,
      relatedEntities,
      this
    );
  };
});

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCases"]),
  },
  created() {
    this.$bind(
      `files`,
      db
        .collection("vehiclePictures")
        .where("entityId", "==", this.$route.params.id)
        .where("deleted", "==", false)
    );
  },
  watch,
  data() {
    let attributes = [].concat(vehiclesFormConfig);
    attributes.push({
      size: "1/1",
      type: "files",
      attribute: "vehiclePictures",
      disabled: ["customer", "supercustomer"].includes(
        this.$store.state.AppActiveUser.role
      ),
      extra: {
        files: () => {
          return this.files;
        },
        config: {
          url: "/",
          uploadMultiple: true,
          parallelUploads: 5,
          method: "put",
          autoQueue: false,
          dictDefaultMessage: this.$t("messages.filesDragAnddrop"),
          acceptedFiles: "image/*",
        },
      },
    });

    let data = {
      entityCases: [],
      files: [],
      collectionName,
      tabs: [
        { name: `${collectionName}.tabs.general`, attributes: attributes },
        {
          name: `${collectionName}.tabs.bands`,
          attributes: this.defineBandsTab(),
        },
        {
          name: `${collectionName}.tabs.criminals`,
          attributes: this.defineCriminalsTab(),
        },
        {
          name: `${collectionName}.tabs.cases`,
          attributes: this.defineCasesTab(),
        },
      ],
    };

    relatedEntities.forEach((r) => {
      let col = r.collectionName;
      this[`${collectionName}${col}`] = [];
      this[`not${collectionName}${col}`] = [];

      this.recalculateRelation(col, r.relationName, r.attributeId);

      data[`${collectionName}${col}`] = this[`${collectionName}${col}`];
      data[`not${collectionName}${col}`] = this[`not${collectionName}${col}`];
    });

    return data;
  },
  methods: {
    defineBandsTab() {
      let collectionName = "bands";
      let relationName = "vehiclesBands";
      let attributeId = "bandId";
      let title = (e) => `${e.alias}`;
      let uniqueKey = "alias";
      let fields = bandsFormConfig;

      let columns = [];
      ["alias"].forEach((a) => {
        columns.push({
          headerName: this.$t(`${collectionName}.fields.${a}`),
          filter: false,
          width: 100,
          field: a,
        });
      });

      return this.listConfig(
        collectionName,
        relationName,
        attributeId,
        title,
        uniqueKey,
        fields,
        columns
      );
    },
    defineCriminalsTab() {
      let collectionName = "criminals";
      let relationName = "criminalsVehicles";
      let attributeId = "criminalId";
      let title = (e) => `${e.name} ${e.surname} (${e.dni})`;
      let uniqueKey = "dni";
      let fields = criminalsFormConfig;

      let columns = [];
      ["dni", "name", "surname", "nickname"].forEach((a) => {
        columns.push({
          headerName: this.$t(`${collectionName}.fields.${a}`),
          filter: false,
          width: 100,
          field: a,
        });
      });

      return this.listConfig(
        collectionName,
        relationName,
        attributeId,
        title,
        uniqueKey,
        fields,
        columns
      );
    },
    defineCasesTab() {
      let columns = createRelatedCasesColumns(this);

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.entityCases;
          },
          filters: [],
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            onRowClicked: (params) => {
              this.$router
                .push("/main/cases/" + params.data.caseId)
                .catch(() => {});
            },
          },
        },
      });

      return config;
    },
    async prevalidate(data, collection, errors) {
      let snapshot = await collection.where("plate", "==", data.plate).get();

      if (snapshot.docs && snapshot.docs.length > 0) {
        if (
          snapshot.docs.length > 1 ||
          snapshot.docs[0].id != this.$route.params.id
        ) {
          errors.add({
            field: "plate",
            msg: this.$t(`Validations.${collectionName}.plate.unique`),
          });
          return true;
        }
      }

      if (!/^[0-9]{4}[A-Z]{3}$/.test(data.plate)) {
        errors.add({
          field: "plate",
          msg: this.$t(`Validations.${collectionName}.plate.invalid`),
        });
        return true;
      }

      return false;
    },

    listConfig(
      otherCollectionName,
      relationName,
      attributeId,
      title,
      uniqueKey,
      fields,
      columns
    ) {
      let onAdd = async (id) => {
        let relationToAdd = {};
        relationToAdd[myRelationAttributeId] = this.$route.params.id;
        relationToAdd[attributeId] = id;
        await db.collection(`${relationName}`).add(relationToAdd);
        this.recalculateRelation(
          otherCollectionName,
          relationName,
          attributeId
        );
      };
      let onRemove = async (id) => {
        await db.collection(`${relationName}`).doc(id).delete();
        this.recalculateRelation(
          otherCollectionName,
          relationName,
          attributeId
        );
      };
      let filterOptions = () => {
        return this[`not${collectionName}${otherCollectionName}`].map((e) =>
          Object.assign({ title: title(e) }, e)
        );
      };
      let data = () => {
        return this[`${collectionName}${otherCollectionName}`];
      };
      let prevalidate = async (data, collection, errors) => {
        if (data[uniqueKey]) {
          let snapshot = await collection
            .where(uniqueKey, "==", data[uniqueKey])
            .get();

          if (snapshot.docs && snapshot.docs.length > 0) {
            errors.add({
              field: uniqueKey,
              msg: this.$t(
                `Validations.${otherCollectionName}.${uniqueKey}.unique`
              ),
            });
            return true;
          }
        }

        if (uniqueKey === "plate") {
          if (!/^[0-9]{4}[A-Z]{3}$/.test(data.plate)) {
            errors.add({
              field: "plate",
              msg: this.$t(`Validations.${otherCollectionName}.plate.invalid`),
            });
            return true;
          }
        }

        return false;
      };

      let filters = [];
      if (
        !["supercustomer", "customer"].includes(
          this.$store.state.AppActiveUser.role
        )
      ) {
        if (fields) {
          //Si no tenemos fields no podemos borrar, agregar ni nada (es readOnly)
          columns.push({
            headerName: "",
            filter: false,
            width: 50,
            field: "id",
            cellRendererFramework: "CellRendererRemove",
            onRemove,
          });

          filters.push({
            size: "1/5",
            mobileSize: "1/2",
            select: true,
            placeholder: `${collectionName}.placeholders.${otherCollectionName}`,
            label: "title",
            clearable: false,
            resetOnOptionsChange: true,
            onChange: (e) => {
              if (e) {
                //Cuando reseteamos nos envía null
                onAdd(e.id);
              }
            },
            options: filterOptions,
          });
          filters.push({
            size: "4/5",
            mobileSize: "1/2",
            sidebar: true,
            i18n: `${otherCollectionName}.buttons.new`,
            extraClasses: "text-right",
            sidebarConfig: {
              title: `${otherCollectionName}.buttons.new`,
              collectionName: otherCollectionName,
              attributes: fields,
              prevalidate,
              afterCreate: onAdd,
            },
          });
        }
      }

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data,
          filters,
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            onRowClicked: () => {},
          },
        },
      });

      return config;
    },
    async recalculateRelation(otherCollectionName, relationName, attributeId) {
      let idsSnapshot = await db
        .collection(`${relationName}`)
        .where(myRelationAttributeId, "==", this.$route.params.id)
        .get();

      let added = [];
      for (let s of idsSnapshot.docs) {
        let id = s.data()[attributeId];
        if (otherCollectionName === "cases") {
          let e = (this.allCases || []).find((x) => x.id === id);
          let entity = { id: s.id };
          entity[attributeId] = id;
          await added.push({ ...e, ...entity });
        } else {
          let e = await (
            await db.collection(otherCollectionName).doc(id)
          ).get();
          let entity = { id: s.id };
          entity[attributeId] = e.id;
          await added.push(Object.assign(entity, e.data()));
        }
      }

      let notAdded = [];

      if (otherCollectionName === "cases") {
        this.allCases.forEach((s) => {
          if (added.map((r) => r[attributeId]).indexOf(s.id) < 0) {
            notAdded.push(Object.assign({ id: s.id }, s));
          }
        });
      } else {
        let notAddedSnapshot = await db.collection(otherCollectionName).get();
        notAddedSnapshot.forEach((s) => {
          if (added.map((r) => r[attributeId]).indexOf(s.id) < 0) {
            notAdded.push(Object.assign({ id: s.id }, s.data()));
          }
        });
      }

      this[`${collectionName}${otherCollectionName}`] = added;
      this[`not${collectionName}${otherCollectionName}`] = notAdded;
    },
  },
};
</script>
