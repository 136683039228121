import modusOperandis from "@/data/bands/modusOperandi";

let fields = [];
fields.push({
  size: "1/2",
  type: "input",
  attribute: "alias",
  validation: "required",
});
fields.push({
  size: "1/2",
  type: "multiple-enum",
  attribute: "modusOperandi",
  extra: {
    enumType: "modusOperandi",
    options: modusOperandis, //.sort((a, b) => { return a.alias.localeCompare(b.alias);})
  },
  multiple: true,
});
fields.push({ size: "1/1", type: "text", attribute: "description" });

export default fields;
